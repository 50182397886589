import React, { useState } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Title from "../components/Title";
import Img from "gatsby-image";
import SEO from "../components/SEO";
import BookingModal from "../components/BookingModal";

export const query = graphql`
  query($slug: String) {
    sanityService(slug: { current: { eq: $slug } }) {
      name
      description
      price
      slug {
        current
      }
      image {
        asset {
          fluid(maxWidth: 1000) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`;

const ServicePage = ({ data, pageContext }) => {
  const { name, description, price, slug, image } = data.sanityService;
  const { base64, ...fluidImage } = image.asset.fluid;
  const [isBooking, setIsBooking] = useState(false);
  return (
    <>
      <SEO
        title={`CLIPS Hair Studio | ${name}`}
        description={description}
        image={pageContext.image}
        url={`https://www.clips-hairstudio.com/service/${slug.current}/`}
      />

      <ServiceContent>
        <Title>{name}</Title>
        <ServiceImage
          fluid={fluidImage}
          alt={name}
          fadeIn={false}
          loading="eager"
        />
        <div>
          <h2>Description</h2>
          <p>{description}</p>
          <p>
            <strong>Price starts at:</strong> ${price}
          </p>
          <BookingButton onClick={() => setIsBooking(true)}>
            Book an Appointment
          </BookingButton>
        </div>
      </ServiceContent>
      <BookingModal
        src={`https://clipshairstudio.as.me/${slug.current}`}
        close={() => setIsBooking(false)}
        active={isBooking}
      />
    </>
  );
};

export default ServicePage;

const ServiceContent = styled.div`
  position: relative;
  top: var(--nav-height);
  padding: 0 2rem 2rem 2rem;
  height: calc(100vh - var(--nav-height));
  .book {
    height: 500px;
    border: solid 2px #b4b1b6;
    overflow-y: scroll;
  }
  @media screen and (min-width: 960px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: var(--nav-height) auto;
    grid-gap: 2rem;
    padding: 0 2rem 0 0;
  }
`;

const ServiceImage = styled(Img)`
  width: 100%;
  height: 300px;
  object-fit: cover;
  box-shadow: var(--shadow);
  @media screen and (min-width: 960px) {
    height: 100%;
    box-shadow: none;
  }
`;

const BookingButton = styled.button`
  display: block;
  margin: 1rem auto;
  background: var(--primary-color);
  font-size: 1rem;
  padding: 0.75em 1em;
  color: white;
  text-decoration: none;
  border-radius: 0.25em;
  border: none;
  margin-bottom: 2rem;
  transition: all 100ms ease-in;
  :hover {
    transform: scale(1.1);
  }
  @media screen and (min-width: 960px) {
    font-size: 1.25rem;
    margin: 1rem 0;
  }
`;
