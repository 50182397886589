import React from "react";
import styled from "styled-components";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { motion, AnimatePresence } from "framer-motion";

const variants = {
  initial: {
    y: 500,
    opacity: 0,
  },
  enter: {
    y: 0,
    opacity: 1,
  },
  exit: {
    y: 500,
    opacity: 0,
  },
};

const BookingModal = ({ src, close, active }) => (
  <AnimatePresence>
    {active && (
      <Modal initial="initial" animate="enter" exit="exit" variants={variants}>
        <CloseBtn onClick={close} />
        <iframe title="booking widget" src={src}></iframe>
      </Modal>
    )}
  </AnimatePresence>
);

export default BookingModal;

const Modal = styled(motion.div)`
  position: fixed;
  top: 0;
  width: 100vw;
  min-height: 100%;
  z-index: 1000;
  overflow: hidden;
  border: solid 2px white;
  box-shadow: var(--shadow);
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(100px);
  iframe {
    padding: 1rem;
    background: rgba(255, 255, 255, 0.9);
    border: 0;
    height: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 800px;
  }
`;

const CloseBtn = styled(AiOutlineCloseCircle)`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
`;
